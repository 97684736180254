import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input, OnInit, EventEmitter, Output, InjectionToken } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fadeInLeftOnEnterAnimation, fadeOutLeftOnLeaveAnimation, slideInLeftAnimation, collapseOnLeaveAnimation, slideInLeftOnEnterAnimation, fadeInRightOnEnterAnimation, slideInDownOnEnterAnimation } from 'angular-animations';
import { filter } from 'rxjs';
import { NavigationBarModel } from 'src/app/core/models/navBar/navBar';
import { AuthService } from 'src/app/core/services';


@Component({
  selector: 'app-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.scss'],
  animations: [fadeInLeftOnEnterAnimation({ duration: 400 }),
    fadeOutLeftOnLeaveAnimation(), slideInLeftAnimation(), collapseOnLeaveAnimation(), slideInLeftOnEnterAnimation({ duration: 400 }), fadeInRightOnEnterAnimation({ duration: 400 }), slideInDownOnEnterAnimation()]
 
})
export class HeaderPublicComponent{
  @Input() linkListPublic!: NavigationBarModel[];
  @Input() isOnboarding!: boolean;
  public showButtons = false;
  onboardingRoutes: string[] = ['/onboarding', '/onboarding/empresas', '/onboarding-particulares'];

  toggleShowButtons(){
    this.showButtons = !this.showButtons
  }
  
  constructor(private router: Router){
    
  }
}
export const MY_TOKEN = new InjectionToken<string>('my-token')
