<div class="terms-container">
  <div class="header-legal">
      <h1>Política de Cookies</h1>
  </div>
  <div class="content-legal">
      <div class="content">
          <img src="../../../../assets/images/box_brand.png" alt="">
          <p><span>DEFINICIÓN Y FUNCIONES DE LAS COOKIES</span>
            <br><br>
            Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
            <br><br>
            <span>¿QUÉ TIPO DE COOKIES UTILIZAMOS EN DENARIUS.ES?</span>
            <br><br>
            <span>Cookies de personalización:</span> Permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc. Actualmente no utilizamos cookies de personalización.
            <br><br>
            <span>Cookies de análisis:</span> Permiten el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida se utiliza en la medición de la actividad de nuestra página web y para la elaboración de perfiles de navegación de los usuarios con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio. Actualmente no utilizamos cookies de análisis.
            <br><br>
            <span>Cookies publicitarias:</span> Permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que se han incluido en nuestra página web en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios. Actualmente no utilizamos cookies publicitarias.
            <br><br>
            <span>Cookies de publicidad comportamental:</span> Permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que se han incluido en nuestra página web. Gracias a ellas, podemos conocer los hábitos de navegación en internet y mostrar publicidad relacionada con su perfil de navegación. Actualmente no utilizamos cookies de publicidad comportamental.
            <br><br>
            <span>BLOQUEAR O ELIMINAR COOKIES</span>
            <br><br>
            Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.
            <br><br>
            Para más información sobre <span>Chrome</span> pulse en <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es">este enlace.</a> <br>
            Para más información sobre <span>Safari</span> pulse en <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">este enlace.</a> <br>
            Para más información sobre <span>Firefox</span> pulse en <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">este enlace.</a> <br>
            Para más información sobre <span>Internet Explorer</span> pulse en <a href="https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">este enlace.</a> <br>
            Para más información sobre <span>Microsoft Edge</span> pulse en <a href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">este enlace.</a></p>
      </div>
      
  </div>
</div>