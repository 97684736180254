import { UserData } from 'src/app/core/models/user/userData';
import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { UserService } from 'src/app/core/services/userArea/user.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit{
  public isLoading = true;
  public myChart : any
  public totalAccounts: any
  public breakpoint:number = 768;
  public baseInnerWidth!:number;
  @Input() userData!: UserData

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.baseInnerWidth = window.innerWidth
      this.createChart(this.baseInnerWidth)
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(((this.baseInnerWidth > this.breakpoint) && (event.target.innerWidth < this.breakpoint)) || ((this.baseInnerWidth < this.breakpoint) && (event.target.innerWidth > this.breakpoint))){
    this.updateVisibility(event.target.innerWidth);
    }
   
  }
  updateVisibility(width: number) {
    this.baseInnerWidth = width
   this.myChart.destroy()
   this.createChart(width)
  }
  createChart(width: number): void {
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    this.myChart = new Chart(ctx, {
      type: 'bar',
      options: {
        maintainAspectRatio: false,
        indexAxis: 'x', 
        plugins:{
          legend: {
            display: false
          },
        }
      },
      data: {
        labels: ['Gastos', 'Ingresos', 'Balance'],
        datasets: [
          {
            barThickness: 70,
            minBarLength: 5,
            data: [this.userData.totalExpenses * -1, this.userData.totalIncome, this.userData.totalBalance],
            backgroundColor: ['rgba(75, 192, 192, 0.5)',  'rgba(115, 255, 11, 0.5)', 'rgba(54, 162, 235, 0.5)'],
            borderColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 205, 86, 0.6)', 'rgba(54, 162, 235, 0.6)'],
            borderWidth: 1
          },
        ]
      },
    });
  }

  
}           