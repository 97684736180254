import { NavigationBarModel } from 'src/app/core/models/navBar/navBar';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInLeftOnEnterAnimation, fadeOutLeftOnLeaveAnimation, slideInLeftAnimation, collapseOnLeaveAnimation, slideInLeftOnEnterAnimation, fadeInRightOnEnterAnimation, slideInDownOnEnterAnimation, expandOnEnterAnimation } from 'angular-animations';
import { environment } from 'src/environments/environment';

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  url: string;
}
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [fadeInLeftOnEnterAnimation({ duration: 400 }),
  fadeOutLeftOnLeaveAnimation(), slideInLeftAnimation(), collapseOnLeaveAnimation(), expandOnEnterAnimation({ duration: 500 }), slideInLeftOnEnterAnimation({ duration: 400 }), fadeInRightOnEnterAnimation({ duration: 400 }), slideInDownOnEnterAnimation()]
})

export class NavBarComponent implements OnInit {
  public _linkList: NavigationBarModel[] = []
  public isProductionEnvironment = environment.production
  @Input() isBurgerMenu: boolean = false;
  @Input() set linkList(val: NavigationBarModel[]) {  
    if (!val) {
      return
    } else {
      const userRole = sessionStorage.getItem('role');
      val.forEach((item,index) => {
        item['linkDeployed'] = false
        this._linkList.push(item)
        if(this.isProductionEnvironment && item.hideInProduction){
          this._linkList.splice(index, 1)
        }
      });
    }

  };
  constructor() {}

  @Output() closeMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  toggleConfig(link: any) {
    this._linkList.forEach((item) => {
      if (item === link) {
        item.linkDeployed = !item.linkDeployed;

      } else {
        item.linkDeployed = false;
      }
    });
   
  }


  closeMenu(){
    this.closeMenuEvent.emit(true)
  }

}
