import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { ChartOptions, ChartData } from 'chart.js';
import { UserData } from 'src/app/core/models/user/userData';
import { UserService } from 'src/app/core/services/userArea/user.service';

@Component({
  selector: 'app-chart-past-month',
  templateUrl: './past-month-chart.component.html',
  styleUrls: ['./past-month-chart.component.scss']
})
export class ChartPastMonthComponent implements AfterViewInit, OnChanges {
  @ViewChild('lineChart') chartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;
  @Input() userData!: UserData;

  currentChartType: string = 'lineChart';
  private myChart!: Chart;
  isGraphView = true;
  selectedTimeInterval = '30d';
  showDropdown = false;
  hoveredDataPoint: any = null;
  timeIntervalDisplayText = 'Ultimos 30 días';
  totalIncomeToday = 0;
  totalBalanceToday = 0;
  totalExpensesToday = 0;

  // Define los intervalos del dropdown
  timeIntervals = [
    { id: '7d', text: 'Ultimos 7 días', value: '7d' },
    { id: '30d', text: 'Ultimos 30 días', value: '30d' },
  ];

  constructor(private cdRef: ChangeDetectorRef, private renderer: Renderer2, private userService: UserService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userData'] && changes['userData'].currentValue) {
      this.extractTodayAmounts();
      this.updateChartData(this.selectedTimeInterval);
    }
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  // Activa el dropdown
  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
    this.toggleDropdownClass();
  }

  toggleDropdownClass(): void {
    this.renderer[this.showDropdown ? 'addClass' : 'removeClass'](this.dropdownMenu.nativeElement, 'show');
  }

  // Selector para usar el dropdown
  selectDropdownOption(option: string): void {
    this.toggleDropdownOption(option);
  }

  // Función para actualizar la data del chart según el rango seleccionado
  private updateChartData(timeInterval: string): void {
    const startDate = new Date();
    let data: number[] = [];
    let labels: string[] = [];

    switch (timeInterval) {
      case '7d':
        startDate.setDate(startDate.getDate() - 7);
        break;
      case '30d':
        startDate.setDate(startDate.getDate() - 30);
        break;
      default:
        throw new Error(`Invalid time interval: ${timeInterval}`);
    }

    if (this.userData && this.userData.accounts) {
      this.userData.accounts.forEach(account => {
        const accountDate = new Date(account.openingDate); // Assuming openingDate is the relevant date
        if (accountDate >= startDate) {
          data.push(account.balance);
          labels.push(this.formatDate(accountDate));
        }
      });

      this.updateChart(data, labels);
    }
  }

  // Activador de la opción seleccionada en el dropdown
  toggleDropdownOption(option: string): void {
    this.selectedTimeInterval = option;
    this.updateChartData(option);
    this.updateTimeIntervalDisplayText(option);
    this.showDropdown = false;
  }

  // Esta función usa la data del chart actualizada y actualiza la composición del chart completo
  updateChart(data: number[], labels: string[]): void {
    if (this.myChart) {
      this.myChart.data.datasets[0].data = data;
      this.myChart.data.labels = labels;
      this.myChart.update();
    }
  }

  // Esta función simplemente cambia el texto del botón del dropdown 
  private updateTimeIntervalDisplayText(option: string): void {
    switch (option) {
      case '7d':
        this.timeIntervalDisplayText = 'Ultimos 7 días';
        break;
      case '30d':
        this.timeIntervalDisplayText = 'Ultimos 30 días';
        break;
      default:
        throw new Error(`Invalid time interval: ${option}`);
    }
  }

  // Función para crear el chart
  private createChart(): void {
    if (this.chartRef && this.chartRef.nativeElement && this.userData && this.userData.accounts) {
      const chartData = this.prepareChartData(this.userData.accounts.map(account => this.formatDate(new Date(account.openingDate))));
      const chartOptions = this.prepareChartOptions();
      this.myChart = new Chart(this.chartRef.nativeElement, {
        type: 'line',
        data: {
          ...chartData,
          datasets: chartData.datasets.map(dataset => ({
            ...dataset,
            label: '',
            tooltip: {
              enabled: false
            },
            borderRadius: 5,
          }))
        },
        options: {
          ...chartOptions,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false,
              mode: 'index',
              intersect: false,
              callbacks: {
                label: () => {
                  return '';
                }
              }
            }
          },
          elements: {
            line: {
              tension: 0.4,
              borderWidth: 2
            },
            point: {
              radius: 5,
              hoverRadius: 12,
              hoverBorderWidth: 3,
              hoverBackgroundColor: 'rgba(255, 205, 86, 0.5)',
              hoverBorderColor: 'rgba(255, 205, 86, 0.6)',
            }
          },
          hover: {
            mode: 'index',
            intersect: false,
          }
        }
      });

      this.chartRef.nativeElement.addEventListener('mousemove', (event: MouseEvent) => {
        const canvasPosition = this.chartRef.nativeElement.getBoundingClientRect();
        const x = event.clientX - canvasPosition.left;
        const y = event.clientY - canvasPosition.top;
        const point = this.myChart.getElementsAtEventForMode(event, 'index', { intersect: false }, true);
        if (point.length) {
          const index = point[0].index;
          this.hoveredDataPoint = this.userData.accounts[index];
          this.totalIncomeToday = this.hoveredDataPoint.incomes;
          this.totalBalanceToday = this.hoveredDataPoint.balance;
          this.totalExpensesToday = this.hoveredDataPoint.expenses;
          this.cdRef.detectChanges();
        }
      });

      this.chartRef.nativeElement.addEventListener('mouseout', () => {
        this.extractTodayAmounts();
        this.hoveredDataPoint = null;
        this.cdRef.detectChanges();
      });
    }
  }

  // Formateo del objeto date para que solo muestre mes y día
  private formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}-${month}`;
  }

  // Preparación de la data del chart
  private prepareChartData(labels: string[]): ChartData {
    return {
      labels: labels,
      datasets: [{
        label: 'Total Balance',
        data: this.userData.accounts.map(account => account.balance),
        borderColor: 'rgba(115, 255, 11, 1)',
        backgroundColor: '#a0ff20',
        pointBackgroundColor: '#a0ff20',
        pointBorderColor: '#a0ff20',
        pointRadius: 5,
        pointHoverRadius: 10,
        pointHitRadius: 10,
        pointHoverBackgroundColor: '#cdff20',
        pointHoverBorderColor: '#a0ff20',
      }]
    };
  }

  // Preparación de las opciones extra del chart
  private prepareChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
          },
          display: true
        },
        y: {
          grid: {
          },
          display: true
        }
      },
      plugins: {
        tooltip: {
          enabled: false,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: () => {
              return '';
            }
          }
        }
      }
    };
  }

  // Función para extraer los datos de las variables tipo amountToday según el día
  private extractTodayAmounts(): void {
    const today = new Date();
    if (this.userData && this.userData.accounts) {
      const todayAccount = this.userData.accounts.find(account => {
        const accountDate = new Date(account.openingDate);
        return accountDate.toDateString() === today.toDateString();
      });
      if (todayAccount) {
        this.totalIncomeToday = todayAccount.incomes;
        this.totalBalanceToday = todayAccount.balance;
        this.totalExpensesToday = todayAccount.expenses;
      }
    }
  }
}