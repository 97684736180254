import { environment } from './../../../../environments/environment';
import { CookiesPolicyComponent } from '../../components/cookies-policy/cookies-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PrivacyPolicyComponent } from 'src/app/shared/components/privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

private path: string = "../../../assets";
public environment = environment;
public isProductionEnviroment: boolean = environment.production;
@Input() isAuth!: boolean

constructor(
  private matIconRegistry: MatIconRegistry,
  private domSanitizer: DomSanitizer,
  private dialog: MatDialog,
  ){
  this.matIconRegistry.addSvgIcon(
    "facebook",
    this.setPath(`${this.path}/facebook.svg`)
  );
  this.matIconRegistry.addSvgIcon(
    "linkedin",
    this.setPath(`${this.path}/linkedin.svg`)
  );
  this.matIconRegistry.addSvgIcon(
    "twitter",
    this.setPath(`${this.path}/twitter.svg`)
  );
  this.matIconRegistry.addSvgIcon(
    "instagram",
    this.setPath(`${this.path}/instagram.svg`)
  );
}
private setPath(url: string): SafeResourceUrl {
  return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
 }

}
