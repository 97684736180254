<!-- header bar  -->
<div [ngClass]="isOnboarding ? 'onboarding-page' : 'toolbar_close'">
  <div class="left-content">
    <div class="menu_btn">
      <button (click)="showMenu()" *ngIf="!isMenuOpen">
        <img src="../../../../assets/images/icons/burger.svg" alt="" />
      </button>
      <button mat-icon-button (click)="hideMenu()" *ngIf="isMenuOpen">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <img
      class="logo"
      src="../../../../assets/images/logos/denarius_mobile.svg"
      alt=""
      routerLink="/"
    />
  </div>
  <div class="right-content">
    <button
      class="gold-color-button"
      routerLink="onboarding/empresas"
      i18n="@@landing.public.title"
    >
      Hazte cliente
    </button>
    <button class="gold-stroked-button" routerLink="/empresas/area-privada"><img src="../../../../assets/images/icons/Login.svg" alt=""><p>Acceso</p></button>
  </div>
</div>
<!-- sidenav open -->
<div
  class="menu_opened"
  *ngIf="isMenuOpen"
  [@fadeInLeftOnEnter]
  [@fadeOutLeftOnLeave]
>
  <div class="sidenav" #sidenav>
    <div class="link_list" *ngIf="!optionMenu">
      <div class="buttons">
          <button
            *ngIf="!hiddenHeader && !showButtons"
            class="gold-color-button"
            routerLink="onboarding/empresas"
            i18n="@@landing.public.title"
            (click)="hideMenu()"
          >
            Hazte cliente
          </button>
          <button (click)="hideMenu()" *ngIf="!hiddenHeader && !showButtons" class="gold-stroked-button" routerLink="/empresas/area-privada"><img src="../../../../assets/images/icons/Login.svg" alt=""><p>Acceso</p></button>
      </div>
      <div *ngIf="!optionMenu" [@fadeInLeftOnEnter] class="linkListContainer">
        <img src="../../../../assets/images/box_gold_light.png" alt="">
        <app-nav-bar
          *ngIf="!hiddenHeader"
          [isBurgerMenu]="true"
          [linkList]="linkListPublic"
          (closeMenuEvent)="receiveCloseMenu($event)"
        ></app-nav-bar>
        <app-nav-bar
          *ngIf="hiddenHeader"
          [isBurgerMenu]="true"
          [linkList]="hiddenHeader ? linkListPrivate : linkListPublic"
          (closeMenuEvent)="receiveCloseMenu($event)"
        ></app-nav-bar>
      </div>
      <div
        class="list_item"
        [@fadeInRightOnEnter]
        (click)="hideMenu()"
      >
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="logout_button">
      <button
        class="gradient_button"
        (click)="hideMenu(); logout()"
        *ngIf="hiddenHeader"
      >
        Cerrar sesión
      </button>
    </div>
  </div>
</div>
